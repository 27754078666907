import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';

const Carousel = ({ children }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalItems = React.Children.count(children);
  const controls = useAnimation();

  // Función para avanzar al siguiente slide
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalItems);
  };

  // Función para retroceder al slide anterior
  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? totalItems - 1 : prevIndex - 1
    );
  };

  // Gesto de deslizar (swipe) con framer-motion
  const handleDragEnd = (event, info) => {
    const offset = info.offset.x;
    const velocity = info.velocity.x;

    if (offset < -100 || velocity < -1000) {
      handleNext();
    } else if (offset > 100 || velocity > 1000) {
      handlePrev();
    }
  };

  // Cambio automático cada 3 segundos
  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 4000);

    return () => clearInterval(interval); // Limpiar el intervalo cuando el componente se desmonta
  }, [currentIndex]);

  return (
    <div className="relative w-full overflow-hidden">
      <motion.div
        className="flex"
        drag="x"
        dragConstraints={{ left: 0, right: 0 }}
        onDragEnd={handleDragEnd}
        initial={{ x: '100%' }}
        animate={{ x: `-${currentIndex * 100}%` }}
        transition={{ type: 'spring', stiffness: 100, damping: 20 }}
      >
        {React.Children.map(children, (child, index) => (
          <motion.div key={index} className="min-w-full h-64 flex items-center justify-center">
            {child}
          </motion.div>
        ))}
      </motion.div>

      {/* Botones de navegación */}
      <button
        onClick={handlePrev}
        className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full hover:bg-gray-700"
      >
        <i className="fa-solid fa-chevron-left"></i>
      </button>

      <button
        onClick={handleNext}
        className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full hover:bg-gray-700"
      >
        <i className="fa-solid fa-chevron-right"></i>
      </button>

      {/* Indicadores de posición */}
      <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {Array.from({ length: totalItems }).map((_, index) => (
          <div
            key={index}
            className={`w-3 h-3 rounded-full ${
              currentIndex === index ? 'bg-blue-500' : 'bg-gray-400'
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
