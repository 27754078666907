import './App.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Karen from './pages/Karen';
import Loader from './pages/Loader';

function App() {
  return (
    <Router>
        <Routes>
            <Route path="/:name" element={<Loader><Karen /></Loader>} />
            <Route path="/:name/:invitation" element={<Loader><Karen /></Loader>} />
            <Route path="/" element={<Loader><Karen /></Loader>} />
        </Routes>
    </Router>
  );
}

export default App;
