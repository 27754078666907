import React from 'react'

export default function Button({onClick, children}) {
  return (
    <button
        onClick={onClick}
        className="bg-green text-white px-12 py-3 rounded-lg hover:bg-black highlight-text"
    >
        {children}
    </button>
  )
}
