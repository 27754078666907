import React from 'react';
import { motion } from 'framer-motion';
import Button from '../../components/Button/Button';

export function ModalContainer({ isOpen, onClose, title, content, children }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -50 }}
        transition={{ duration: 0.3 }}
        className="bg-white p-6 rounded-lg shadow-lg max-w-lg h-2/6 w-10/12 text-black"
      >
        <div className="flex flex-col justify-evenly h-full">
          {children}
        </div>
      </motion.div>
    </div>
  );
}