import React from 'react';

const ClickableImage = ({ src, alt, onClick }) => {
  return (
    <div className="max-md:w-9/12 w-5/12  h-64 overflow-hidden cursor-pointer" onClick={onClick}> 
      <img
        src={src}
        alt={alt}
        className="w-full h-full object-cover transform transition-transform duration-500 hover:scale-110"
      />
    </div>
  );
};

export default ClickableImage;
