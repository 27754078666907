import React from 'react';
import { motion } from 'framer-motion';
import Button from '../../components/Button/Button';

const MapModal = ({ isOpen, onClose, src }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -50 }}
        transition={{ duration: 0.3 }}
        className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full text-black h-5/6 flex flex-col justify-evenly"
      >
        <div className="w-full h-5/6">
          {/* Aquí va el mapa de Google Maps */}
          <iframe
            title="Google Maps"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15434.02321534612!2d-91.162236!3d14.7405099!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85894b8fa76d3cbf%3A0x1c3db4c0e4b055eb!2sHotel%20y%20Centro%20de%20Convenciones%20Jardines%20del%20Lago!5e0!3m2!1sen!2sgt!4v1726930252975!5m2!1sen!2sgt"
            className="w-full h-full"
            style={{ border: 0 }}
            aria-hidden="false"
            tabIndex="0"
          />
          </div>
          <Button
            onClick={()=>onClose()}
           >
            Cerrar
          </Button>
        </motion.div>
        </div>
  );
};

export default MapModal;
