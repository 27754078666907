import React, { useState }  from 'react'
import LoadingScreen from '../components/Loading/Loading'

export default function Loader({children}) {
    const [isLoadingComplete, setIsLoadingComplete] = useState(false);

    const handleLoadingComplete = () => {
      setIsLoadingComplete(true); // El modal desaparece, pero el contenido ya está cargado
    };
  return (
    <>
    
    {!isLoadingComplete && <LoadingScreen onComplete={handleLoadingComplete} />}
    {children}
    </>
  )
}
