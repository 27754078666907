import React, { useEffect, useState } from 'react'
import HeaderContainer from '../layouts/HeaderContainer/HeaderContainer'
import AnimatedText from '../components/Animations/AnimatedText/AnimatedText'
import SeparatorContainer from '../layouts/SeparatorContainer/SeparatorContainer'
import ZoomOutText from '../components/Animations/ZoomOutText/ZoomOutText'
import FlatContainer from '../layouts/FlatContainer/FlatContainer'
import { ModalContainer } from '../layouts/ModalContainer/ModalContainer'
import OverlayContainer from '../layouts/OverlayContainer/OverlayContainer'
import ImageContainer from '../layouts/ImageContainer/ImageContainer'
import { useParams } from 'react-router-dom'
import RoundImage from '../components/Image/RoundImage'
import MapModal from '../layouts/MapModal/MapModal'
import Button from '../components/Button/Button'
import { sendWhatsappMessage } from '../helpers/helpers'
import Carousel from '../components/Carousel/Carousel'
import FlatImage from '../components/Image/FlatImage'
import ImageWModal from '../components/Image/ModalImage'
import ClickableImage from '../components/Image/ClickableImage'
import ModalImage from '../components/Image/ModalImage'
import axios from 'axios'

import Cemaco from '../assets/images/Cemaco.png'
import KarenCanche from '../assets/images/KarenCanche.jpg'
import Bg1C from '../assets/images/Bg1C.jpg'
import Bg1_B from '../assets/images/Bg1_B.jpg'
import JardinesDelLago from '../assets/images/Jardines.jpg'
import JardinesDelLago2 from '../assets/images/Jardines2.png'
import KJ2 from '../assets/images/KJ2.jpg'
import KJ5 from '../assets/images/KJ5.jpg'
import KJ6 from '../assets/images/KJ6.jpg'
import KJ7 from '../assets/images/KJ7.jpg'
import KJ8 from '../assets/images/KJ8.jpg'
import KJ9 from '../assets/images/KJ9.jpg'
import KJ11 from '../assets/images/KJ11.jpg'
import KJ12 from '../assets/images/KJ12.jpg'
import KJ13 from '../assets/images/KJ13.jpg'
import KJ14 from '../assets/images/KJ14.jpg'
import KJ15 from '../assets/images/KJ15.jpg'
import Dress from '../assets/images/Dress.png'

export default function Karen() {
    const { name } = useParams();
    const [invited, setInvited] = useState('Invitación no válida');
    const [index, setIndex] = useState(-1);
    const [invitedAmount, setInvitedAmount] = useState('N/A');
    const [acceptedAmount, setAcceptedAmount] = useState(0);
    const [invitedNumberList, setInvitedNumberList] = useState([]);	

    useEffect(() => {
        document.title = `José y Karen - ${name}`;
        axios.post('https://norse-lens-274518.uc.r.appspot.com/api/google-sheets/search', { "search": name })
            .then(response => {
                const data = response.data;
                setInvited(data.values[1]);
                setInvitedAmount(data.values[2]);
                setAcceptedAmount((parseInt(data.values[2])));
                setInvitedNumberList(Array.from(Array(parseInt(data.values[2])+1).keys()));
                setIndex(data.index);
            })
            .catch(error => {
                console.error('Error al obtener la invitación:', error);
            });
    }, [name]);

    // useEffect(() => {
    //     if (csvData.length > 0) {
    //         const result = csvData.find(row => row[0] === name);
    //         if (result) {
    //             setInvited(result[1]);
    //             setInvitedAmount(result[2]);
    //             setAcceptedAmount((parseInt(result[2])));
    //             setInvitedNumberList(Array.from(Array(parseInt(result[2])+1).keys()));
    //         }
    //     }
    // }, [csvData, name]);

    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleOpenModal = (src) => {
        setSelectedImage(src);
        setIsImageModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsImageModalOpen(false);
    };

    const handleWazeButtonClick = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        let url;
        console.log(userAgent);
        if (/android/i.test(userAgent)) {
            // Dispositivo Android¿
            url = 'waze://?ll=14.740475,-91.161766&navigate=yes';
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            // Dispositivo iOS
            url = 'waze://?ll=14.740475,-91.161766&navigate=yes';
        } else {
            // PC o navegador web
            url = 'https://www.waze.com/es/live-map/directions/jardines-del-lago-calle-monterrey-panajachel?to=place.w.176160915.1761871298.1634038';
        }

        window.open(url, '_blank');
    };

    const sendConfirmation = () => {
        console.log(index);
        console.log(invitedAmount);
        axios.post('https://norse-lens-274518.uc.r.appspot.com/api/google-sheets/write', { "index": index, "values": [[acceptedAmount != 0 ? 'Si' : 'No',  acceptedAmount, 0]] })  
            .then(response => {
                setModalOpen(true);
            })
            .catch(error => {
                console.error('Error al enviar la confirmación:', error);
            });
    }

    const [isModalOpen, setModalOpen] = useState(false);
    const [isMapModalOpen, setMapModalOpen] = useState(false);
    return (
        <>
            <HeaderContainer>
                <div className='bg-black bg-opacity-35 py-5'>
                    <ZoomOutText><div className='text-6xl highlight-text'>José y Karen</div></ZoomOutText>
                    <ZoomOutText><div className='text-2xl pt-5'>14-12-24 - 15:00 hrs</div></ZoomOutText>
                </div>
            </HeaderContainer>

            {/* Mensaje de bienvenida */}
            <SeparatorContainer>
                <AnimatedText><div className='text-2xl'>Es un honor para nosotros:</div></AnimatedText>
                <div className='flex flex-row my-5'>
                    <div className='w-1/2 text-center self-center'>
                        <ZoomOutText><div className='text-4xl self-center highlight-text'>José Miguel Morán Cordova</div></ZoomOutText>
                    </div>
                    <div className='w-1/2 text-center self-center'>
                        <ZoomOutText><div className='text-4xl self-center highlight-text'>Karen Denisse Menéndez Tobías</div></ZoomOutText>
                    </div>
                </div>
                <AnimatedText><div className='text-2xl'>{invitedAmount == 1 ? 'Invitarte' : 'Invitarlos'} a nuestra boda</div></AnimatedText>
            </SeparatorContainer>

            {/* Ubicación */}
            <ImageContainer imagesrc={JardinesDelLago2}>
                <OverlayContainer>
                    <AnimatedText>
                        <div className='text-6xl pb-10  highlight-text'>Ubicación</div>
                    </AnimatedText>
                    <AnimatedText>
                        <div className='text-3xl'>Hotel Jardines del Lago</div>
                        <div className='text-2xl'>Calle Monterey, Panajachel, Guatemala</div>
                    </AnimatedText>
                    {/* <div className='flex flex-row justify-center'>
                        <ZoomOutText>
                            <RoundImage src={JardinesDelLago2} alt={'Arika Venue'} />
                        </ZoomOutText>
                    </div> */}
                    <AnimatedText>
                        <div className='text-3xl'>Sábado</div>
                        <div className='text-3xl'>14 de Diciembre</div>
                        <div className='text-3xl pb-2'>15:00 PM</div>
                    </AnimatedText>
                    <div className='flex flex-row justify-evenly pb-4'>
                        <ZoomOutText>
                            <Button
                                onClick={() => setMapModalOpen(true)}
                            >
                                <i className="fa-regular fa-map"></i> Mapa
                            </Button>
                        </ZoomOutText>
                        <ZoomOutText>
                            <Button
                                onClick={() => handleWazeButtonClick()}
                            >
                                <i className="fa-brands fa-waze"></i> Waze
                            </Button>
                        </ZoomOutText>
                    </div>
                </OverlayContainer>
            </ImageContainer>

            {/* Mensaje de Invitados */}
            <SeparatorContainer>
                <AnimatedText><div className='text-xl'>Es un gusto que nos acompañe{invitedAmount == 1 ? 's' : 'n'}</div></AnimatedText>
                <ZoomOutText><div className='text-4xl py-5 highlight-text'>{invited}</div></ZoomOutText>
            </SeparatorContainer>

            {/* Itinerario */}
            <ImageContainer imagesrc={KJ7}>
                <OverlayContainer>
                    <AnimatedText>
                        <div className='text-6xl pb-10  highlight-text'>Itinerario</div>
                    </AnimatedText>
                    <Carousel>
                        <div>
                            <div className='flex flex-row justify-center'>
                                <ZoomOutText>
                                    <i className="text-8xl fa-solid fa-church"></i>
                                </ZoomOutText>
                            </div>
                            <div className='text-5xl py-4'>Misa</div>
                            <div className='text-3xl pb-8'>15:00 hrs</div>
                        </div>
                        <div>
                            <div className='flex flex-row justify-center'>
                                <ZoomOutText>
                                    <i className="text-8xl fa-solid fa-champagne-glasses"></i>
                                </ZoomOutText>
                            </div>
                            <div className='text-5xl py-4'>Fiesta</div>
                            <div className='text-3xl pb-8'>18:00 hrs</div>
                        </div>
                    </Carousel>
                </OverlayContainer>
            </ImageContainer>

            {/* Invitación válida */}
            <SeparatorContainer type='inverted'>
                <AnimatedText><div className='text-3xl'>Invitación válida para {invitedAmount} persona{invitedAmount > 1 ? 's' : ''}</div></AnimatedText>
            </SeparatorContainer>

            {/* Hospedaje */}
            <ImageContainer imagesrc={JardinesDelLago}>
                <OverlayContainer>
                    <AnimatedText>
                        <div className='text-6xl pb-10  highlight-text'>Hospedaje</div>
                    </AnimatedText>
                    <AnimatedText>
                        <div className='text-3xl'>Hotel Jardines del Lago</div>
                    </AnimatedText>
                    {/* <div className='flex flex-row justify-center'>
                        <ZoomOutText>
                            <RoundImage src={'/Arika.png'} alt={'Arika Venue'} />
                        </ZoomOutText>
                    </div> */}
                    <AnimatedText>
                        <div className='text-3xl py-2'>Contacto</div>
                        <div className='text-3xl pb-2'>Jorge Cortéz</div>
                    </AnimatedText>
                    <ZoomOutText>
                                <Button
                                    onClick={() => sendWhatsappMessage('50259290790', 'Hola Jorge! Estamos interesados en hospedarnos en el hotel para la boda Morán Menéndez.')}
                                >
                            <i className="fa-brands fa-whatsapp"></i> 5929-0790
                        </Button>
                    </ZoomOutText>
                </OverlayContainer>
            </ImageContainer>

            {/* Padres */}
            <SeparatorContainer>
                <AnimatedText><div className='text-2xl'>Con la bendición de Dios y nuestros padres:</div></AnimatedText>
                <ZoomOutText><div className='text-xl my-2'>Padres del novio:</div></ZoomOutText>
                <div className='flex flex-row my-1'>
                    <div className='w-1/2 text-center self-center'>
                        <ZoomOutText><div className='text-3xl self-center highlight-text'>Leyvi Córdova</div></ZoomOutText>
                    </div>
                    <div className='w-1/2 text-center self-center'>
                        <ZoomOutText><div className='text-3xl self-center highlight-text'>José Luis Morán</div></ZoomOutText>
                    </div>
                </div>
                <ZoomOutText><div className='text-xl my-2'>Padres de la novia:</div></ZoomOutText>
                <div className='flex flex-row my-1'>
                    <div className='w-1/2 text-center self-center'>
                        <ZoomOutText><div className='text-3xl self-center highlight-text'>Dina Maribel Tobías</div></ZoomOutText>
                    </div>
                    <div className='w-1/2 text-center self-center'>
                        <ZoomOutText><div className='text-3xl self-center highlight-text'>Ricardo Menéndez</div></ZoomOutText>
                    </div>
                </div>
            </SeparatorContainer>

            {/* Asistencia */}
            <ImageContainer imagesrc={KJ5}>
                <OverlayContainer>
                    <AnimatedText>
                        <div className='text-6xl pb-10 highlight-text'>Confirma tu asistencia</div>
                        <div className='text-2xl'>¡Nos encantaría contar contigo! Por favor, selecciona cuántas personas asistirán al evento.</div>
                    </AnimatedText>
                    <AnimatedText>
                        <select className='bg-white border-2 border-black rounded-lg w-5/12 px-4 py-2 mt-4 text-black text-xl text-bold' value={acceptedAmount} onChange={(e) => setAcceptedAmount(e.target.value)}>
                            {invitedNumberList.map((number, index) => (
                                <option key={index} value={number}>{number}</option>
                            ))}
                        </select>
                        <div className='text-2xl my-8'>Enviando 0, avisas que no podrás asistir.</div>
                    </AnimatedText>
                    <ZoomOutText>
                        <Button onClick={() => sendConfirmation()}>
                            Confirmar Asistencia
                        </Button>
                    </ZoomOutText>
                </OverlayContainer>
            </ImageContainer>

            {/* Vestuario */}
            <FlatContainer>
                <AnimatedText>
                    <div className='text-6xl text-black pb-10  highlight-text'>Vestuario</div>
                </AnimatedText>
                <ZoomOutText>
                    <div className="flex flex-row justify-center">
                        <div ><img src={Dress} className="dress" /></div>	    
                    </div>
                </ZoomOutText>
                <AnimatedText>
                    <div className='text-3xl text-black  highlight-text'>Formal</div>
                </AnimatedText>
            </FlatContainer>

            {/* Mesa de Regalos */}
            <ImageContainer imagesrc={KJ8}>
                <OverlayContainer>
                    <AnimatedText>
                        <div className='text-6xl pb-10  highlight-text'>Mesa de Regalos</div>
                    </AnimatedText>
                    <ZoomOutText>
                        <FlatImage src={'/Cemaco.png'} alt={'Cemaco'} />
                    </ZoomOutText>
                    <ZoomOutText>
                        <Button
                            onClick={()=>window.open('https://www.cemaco.com/list/bodamoranmenendez', '_blank')}
                        >
                            <i className="fa-solid fa-gift"></i> Ir a mesa de regalos
                        </Button>
                    </ZoomOutText>
                    <AnimatedText>
                        <div className='text-2xl'>
                            ¡Recuerda que el mejor regalo es compartir este momento con nosotros!
                        </div>
                    </AnimatedText>


                </OverlayContainer>
            </ImageContainer>

            {/* Galería de imágenes */}
            <SeparatorContainer type='inverted'>
                <AnimatedText>
                    <div className='text-6xl pb-10  highlight-text'>Galería</div>
                </AnimatedText>
                <Carousel>
                    <ClickableImage src={KarenCanche} alt="Imagen 1" onClick={() => handleOpenModal(KarenCanche)} />
                    <ClickableImage src={Bg1C} alt="Imagen 1" onClick={() => handleOpenModal(Bg1C)} />
                    <ClickableImage src={KJ2} alt="Imagen 1" onClick={() => handleOpenModal(KJ2)} />
                    <ClickableImage src={KJ5} alt="Imagen 1" onClick={() => handleOpenModal(KJ5)} />
                    <ClickableImage src={KJ6} alt="Imagen 1" onClick={() => handleOpenModal(KJ6)} />
                    <ClickableImage src={KJ7} alt="Imagen 1" onClick={() => handleOpenModal(KJ7)} />
                    <ClickableImage src={KJ8} alt="Imagen 1" onClick={() => handleOpenModal(KJ8)} />
                    <ClickableImage src={KJ9} alt="Imagen 1" onClick={() => handleOpenModal(KJ9)} />
                </Carousel>
            </SeparatorContainer>

            {/* Contacto */}
            <ImageContainer image='1'>
                <OverlayContainer>
                    <AnimatedText>
                        <div className='text-6xl pb-10 highlight-text'>Contacto</div>
                        <div className='text-2xl'>¡Envía tu preguntas y buenos deseos a los novios!</div>
                    </AnimatedText>
                    <div className='flex flex-row justify-evenly'>
                        <ZoomOutText>
                            <div className='flex flex-col justify-center px-2'>
                                <div className='text-3xl mb-4  highlight-text'>José Miguel</div>
                                <Button
                                    onClick={() => sendWhatsappMessage('50241509718', 'Hola José Miguel!')}
                                >
                                    <i className="fa-brands fa-whatsapp"></i> 4150-9718
                                </Button>
                            </div>
                        </ZoomOutText>
                        <ZoomOutText>
                            <div className='flex flex-col justify-center px-2'>
                                <div className='text-3xl mb-4  highlight-text'>Karen</div>
                                <Button
                                    onClick={() => sendWhatsappMessage('50250184790', 'Hola Karen!')}
                                >
                                    <i className="fa-brands fa-whatsapp"></i> 5018-4790
                                </Button>
                            </div>
                        </ZoomOutText>
                    </div>
                </OverlayContainer>
            </ImageContainer>

            {/* Mensaje Final */}
            <SeparatorContainer>
                <AnimatedText><div className='text-4xl'>¡Es un gusto que puedas acompañarnos!</div></AnimatedText>
            </SeparatorContainer>

            <ModalContainer isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
                <div className='text-2xl'>¡Gracias por confirmar!</div>
                <div className='text-2xl'>{acceptedAmount==0?"¡Sentimos no poder contar con tu presencia!":"¡Esperamos tu asistencia!"}</div>
                <Button onClick={() => setModalOpen(false)}>Cerrar</Button>
            </ModalContainer>
            <MapModal isOpen={isMapModalOpen} onClose={() => setMapModalOpen(false)} />
            <ModalImage isOpen={isImageModalOpen} src={selectedImage} alt="Imagen ampliada" onClose={handleCloseModal} />
        </>
    )
}
