import React from 'react'
// import './HeaderContainer.css'

export default function SeparatorContainer({type='normal',children}) {
  let className = 'w-full h-auto py-10 bg-green place-content-center text-center px-3'
  if (type === 'inverted') {
    className = 'w-full h-auto py-10 bg-rosao text-black place-content-center text-center px-3'
  }
  return (
    <div className={className}>
        {children}
    </div>
  )
}