import React, { useState } from 'react';
import { motion } from 'framer-motion';

const LoadingScreen = ({ onComplete }) => {
    const [isVisible, setIsVisible] = useState(true);

    // Función para manejar el clic o el gesto de deslizar hacia arriba
    const handleSlideUp = () => {
        setIsVisible(false);
        setTimeout(() => {
            onComplete(); // Notificar que la animación terminó
        }, 800); // Ajustar el tiempo según la duración de la animación
    };

    return (

        <motion.div
            className="fixed inset-0 bg-orange-100 flex items-center justify-center z-50"
            initial={{ y: 0 }}
            animate={{ y: isVisible ? 0 : '-100%' }}
            exit={{ y: '-100%' }}
            transition={{ duration: 0.8 }}
            onClick={handleSlideUp} // Deslizar hacia arriba con clic
            drag="y" // Arrastre vertical
            dragConstraints={{ top: 0, bottom: 0 }}
            onDragEnd={(event, info) => info.offset.y < -100 && handleSlideUp()} // Deslizar hacia arriba
        >
            <div className='flex flex-col justify-center items-center h-screen heading-text'>
                <div className='flex flex-col justify-center items-center h-5/6'>
                    <div className="text-black text-4xl text-center mb-5">
                        Boda
                    </div>
                    <div className="text-black text-6xl text-center">
                        Morán Menéndez
                    </div>
                </div>
                <motion.div 
                    className="text-black text-3xl text-center align-text-bottom mb-5"
                >
                    Swipe up
                </motion.div>
            </div>
        </motion.div>
    )

};

export default LoadingScreen;
