import React from 'react'

export default function FlatImage({ src, alt }) {
  return (
    <div className="overflow-hidden flex justify-center">
      <img
        src={src}
        alt={alt}
        className="object-cover max-md:w-10/12 w-5/12  h-full"
      />
    </div>
  )
}
